<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power" />
      {{ $i18n.translate('forms.transl55') }}
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl86')"
                  v-model.trim="$v.form.producer.$model"
                  :isValid="checkIfValid('producer')"
                  :placeholder="$i18n.translate('forms.transl86')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CInput
              maxlength="20"
              :label="$i18n.translate('forms.transl87')"
              v-model.trim="$v.form.purchase_date.$model"
              :placeholder="$i18n.translate('forms.transl87')"
              :isValid="checkIfValid('purchase_date')"
              :invalidFeedback="$i18n.translate('forms.transl30')"
            />

            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl88')"
                  v-model.trim="$v.form.vin.$model"
                  :isValid="checkIfValid('vin')"
                  :placeholder="$i18n.translate('forms.transl88')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="30"
                  :label="$i18n.translate('forms.transl89')"
                  v-model.trim="$v.form.registration_number.$model"
                  :isValid="checkIfValid('registration_number')"
                  :placeholder="$i18n.translate('forms.transl89')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CInput
              maxlength="255"
              :label="$i18n.translate('forms.transl90')"
              v-model.trim="$v.form.trailer_number.$model"
              :placeholder="$i18n.translate('forms.transl90')"
              :isValid="checkIfValid('trailer_number')"
              :invalidFeedback="$i18n.translate('forms.transl30')"
            />

            <CSelect id="status"
                     :label="$i18n.translate('forms.transl96')"
                     v-model.trim="$v.form.status.$model"
                     :options="statuses"
                     :isValid="checkIfValid('status')"
            >
            </CSelect>


            <!--            <CButton-->
            <!--              color="primary"-->
            <!--              :disabled="$v.form.$invalid"-->
            <!--              @click="submit"-->
            <!--            >-->
            <CButton
              color="primary"
              @click="submit"
            >
               {{ $i18n.translate('forms.transl52') }}
            </CButton>
            <!--            <CButton-->
            <!--              class="ml-1"-->
            <!--              color="success"-->
            <!--              :disabled="$v.form.$invalid"-->
            <!--              @click="validate"-->
            <!--            >-->
            <!--              Validate-->
            <!--            </CButton>-->
            <!--            <CButton-->
            <!--              class="ml-1"-->
            <!--              color="danger"-->
            <!--              :disabled="!isDirty"-->
            <!--              @click="reset"-->
            <!--            >-->
            <!--              Reset-->
            <!--            </CButton>-->
            <CButton
  class="ml-1"
  color="success"
  @click="goBack"
>
  {{ $i18n.translate('forms.transl53') }}
</CButton>

          </CForm>
          <br />
        </CCol>
      </CRow>
    </CCardBody >
  </CCard >
</template >

<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"

export default {
  data() {
    return {
      form: {
        producer: null,
        purchase_date: null,
        vin: null,
        status: null,
        registration_number: null,
        trailer_number: null,
      },
      statuses: [],
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      producer: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      purchase_date: {
        minLength: minLength(3),
        maxLength: 20
      },
      vin: {
        minLength: minLength(3),
        maxLength: 255
      },
      registration_number: {
        required,
        minLength: minLength(3),
        maxLength: 30
      },
      trailer_number: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      status: {
      },
    }
  },
  methods: {
    goBack() {
      this.$router.replace({path: '/carriers/' + this.$route.params.id + '/edit'})
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    store() {
      let self = this;
      axios.post(this.$apiAdress + '/api/carrier-cars/store/' + this.$route.params.id + '?token=' + localStorage.getItem("api_token")+ '&s_lng=' + self.$i18n.locale(),
        {
          userId: this.$route.params.id,
          producer: self.form.producer,
          purchase_date: self.form.purchase_date,
          vin: self.form.vin,
          status: self.form.status,
          registration_number: self.form.registration_number,
          trailer_number: self.form.trailer_number,
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
              self.$i18n.translate('forms.transl63'),
              'success'
            ).then(function () {
              self.$router.push('/carriers/' + self.$route.params.id + '/edit');
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        }).catch(function (error) {
        if (error.response.data.message != '') {
          let errorDetails = ""
          for (let key in error.response.data.errors) {
            errorDetails += `${error.response.data.errors[key]}<br/>`
          }
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            errorDetails,
            'error'
          )
          window.scrollTo({top: 0});
        } else {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            error,
            'error'
          )
          self.$router.push({path: 'login'});
        }
      });
    },

    submit() {
      this.validate();
      if(!this.$v.form.$invalid) {
        this.store();
      }
    },

    validate() {
      this.$v.$touch()
    },

    reset() {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },

    getEmptyForm() {
      return {
        producer: null,
        purchase_date: null,
        vin: null,
        status: null,
        registration_number: null,
        trailer_number: null,

      }
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/carrier-cars/create/' + this.$route.params.id + '?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.statuses = response.data;
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });
  }
}
</script>

